/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

const AssignCompanyPopup = ({
  selectedFaqId = null,
  setSelectedFaqId = () => {},
  afterPopupClose = () => {},
}) => {
  const token = localStorage.getItem("token");

  const { t } = useTranslation();

  const [companyLoading, setCompanyLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [faqCompanies, setFaqCompanies] = useState([]);

  const [companyName, setCompanyName] = useState("");

  const [checkedCompanies, setCheckedCompanies] = useState({}); // To store checked state

  const [isSaving, setisSaving] = useState(false);

  const [validation, setValidation] = useState({
    companyError: false,
  });

  //validation function
  const validationHandler = () => {
    let isValid = true;

    if (faqCompanies.length === 0) {
      setValidation((prevState) => ({ ...prevState, companyError: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get company list
  const getAllCompanyList = async (isLoading = false, titleString = "") => {
    try {
      if (isLoading) {
        setCompanyLoading(true);
      }

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&modulefrom=backend`;

      if (titleString !== "") {
        requestURL += `&filtername=${titleString}`;
      }

      const response = await getData(requestURL);

      setCompanyLoading(false);

      console.log(response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for check expert
  const companyCheckHandler = (companyId, isChecked) => {
    setCheckedCompanies((prevState) => ({
      ...prevState,
      [companyId]: isChecked, // Update checked state for the specific expert
    }));

    if (isChecked) {
      // Add to faqExperts if checked, if not already there
      if (!faqCompanies.includes(companyId)) {
        setFaqCompanies((prevState) => [...prevState, companyId]);
      }
    } else {
      // Remove from faqExperts if unchecked
      setFaqCompanies((prevState) =>
        prevState.filter((id) => id !== companyId)
      );
    }
  };

  //get faq details
  const getFaqDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_FAQ_DETAILS +
        `/${selectedFaqId}?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        const data = response.data;

        if (data.companyids && data.companyids.length > 0) {
          for (let cpmanyId of data.companyids) {
            companyCheckHandler(cpmanyId, true);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //save faq function
  const saveFaqHandler = async (e) => {
    if (validationHandler()) {
      try {
        setisSaving(true);

        let apiData = {
          companyids: faqCompanies,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (selectedFaqId) {
          requestURL += url.API_UPDATE_FAQ + `/${selectedFaqId}?token=${token}`;

          response = await putData(requestURL, apiData);
        }

        setisSaving(false);

        console.log(response);

        if (response.status) {
          // close the popup
          let offCanvasPopup = document.querySelector(
            "#offcanvasAssignCompany"
          );
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();

          resetHandler();
          afterPopupClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const resetHandler = () => {
    setCompanyList([]);
    setFaqCompanies([]);
    setCompanyName("");
    setCheckedCompanies({});
    setSelectedFaqId(null);

    setValidation({
      companyError: false,
    });
  };

  useEffect(() => {
    if (selectedFaqId) {
      getAllCompanyList(true);
      getFaqDetails();
    }
  }, [selectedFaqId]);

  useEffect(() => {
    if (companyName !== "" && companyName.length > 0) {
      const filterSearch = companyList.filter((company) =>
        company.name.toLowerCase().includes(companyName.toLowerCase())
      );

      if (filterSearch.length === 0) {
        getAllCompanyList(false, companyName);
      }
    } else {
      const timeoutId = setTimeout(() => {
        getAllCompanyList(false);
      }, 500); // Debounce the input for better UX
      return () => clearTimeout(timeoutId);
    }
  }, [companyName]);

  // Filtered list based on search input if length > 2
  const filteredCompanies =
    companyName.length > 2
      ? companyList.filter((company) =>
          company.name.toLowerCase().includes(companyName.toLowerCase())
        )
      : companyList;

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasAssignCompany"
      aria-labelledby="offcanvasAssignCompanyLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasAssignCompanyLabel">
          Assign Sponsors
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="field-container flex-fill">
            <div className="search position-relative mb-4">
              <button className="d-block position-absolute top-50 border-0 text-primary material-symbols-outlined bg-transparent ms-3 translate-middle-y">
                search
              </button>
              <input
                type="text"
                className="form-control bg-gray-100 rounded-0 shadow-none ps-5"
                placeholder="Search content by name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              {validation.companyError && (
                <div className="text-danger mt-2">
                  Please select at least one company.
                </div>
              )}
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Sponsors
              </label>
              <ul
                className="experts"
                id="appcourses"
                style={{ maxHeight: "400px", overflowY: "auto" }}
              >
                {companyLoading
                  ? [1, 2, 3].map((item, index) => {
                      return (
                        <li
                          key={index}
                          className="px-3 py-3 bg-gray-100 text-gray d-flex align-items-center gap-3 placeholder-glow"
                        >
                          <span className="placeholder col-6"></span>
                        </li>
                      );
                    })
                  : filteredCompanies.map((company, index) => {
                      return (
                        <li
                          key={index}
                          className="px-3 py-3 bg-gray-100 d-flex align-items-center gap-3"
                        >
                          <input
                            type="checkbox"
                            className="w-auto"
                            id={`expert_checkbox_${company._id}`}
                            checked={!!checkedCompanies[company._id]} // Checked state
                            value={company._id}
                            onChange={(e) =>
                              companyCheckHandler(company._id, e.target.checked)
                            }
                          />
                          <span className="d-block">{company.name}</span>
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>

          <div className="action d-flex flex-wrap gap-3 justify-content-between">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {t("Close")}
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveFaqHandler}
              disabled={isSaving}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
            >
              {t("Save Faq")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssignCompanyPopup;
